
import { Options, Vue } from "vue-class-component";
import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
import {bindDevice, getSetting, getInfo} from "@/api/goods";
import { showToast } from "vant";

declare const window: any;
@Options({
  name: "Setting",
  components: {
    HelloWorld,
  },
})
export default class Home extends Vue {
  code = "";
  mac : any;
  mess = "";
  isSequencePrint = "2";
  printType = "1";
  appendPrint = "2";
  printTypeDisabled = false;

  changePrint = (e: string) => {
    this.printTypeDisabled = e === '1' ? true : false;
    this.printType = '1';
    this.appendPrint = e;
  }
  onClickLeft = async () => {

    const rest = await bindDevice(window.mac, this.code);

    this.mess = this.code + window.mac + JSON.stringify(rest);
    //history.back();
  };

  //保存配置
  submit = async () => {
    const res: any = await getSetting(window.mac, this.printType, this.appendPrint, this.isSequencePrint);
    showToast(res.msg);
  };
  showPickerCheckbox(){

  }

  //获取默认配置
  getConfiguration = async () => {
    const res = await getInfo(window.mac);
    this.code = res.data.equipmentSn;
    this.printType = res.data.printType;
    this.appendPrint = res.data.isAppendPrint;
    this.isSequencePrint = res.data.isSequencePrint;
  }
  async activated(){
    window.mac = this.$route.query.mac
    await this.getConfiguration();
  }

  async mounted() {
    window.mac = this.$route.query.mac
    await this.getConfiguration();
  }
}
